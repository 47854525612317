<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>
    <div v-if="iszh">
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_0.jpg" class="rounded-0"></b-card-img-lazy>   
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_1.jpg" class="rounded-0"></b-card-img-lazy>      
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_2.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_3.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_4.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_5.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_6.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_7.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_8.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_9.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_10.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_11.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_12.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_13.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_14.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_15.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_16.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_17.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_18.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_19.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_20.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_21.jpg" class="rounded-0"></b-card-img-lazy>
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_22.jpg" class="rounded-0"></b-card-img-lazy>
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_cn_23.jpg" class="rounded-0"></b-card-img-lazy>
    </div>
  <div v-else>
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_0.jpg" class="rounded-0"></b-card-img-lazy>   
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_1.jpg" class="rounded-0"></b-card-img-lazy>      
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_2.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_3.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_4.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_5.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_6.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_7.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_8.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_9.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_10.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_13.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_14.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_15.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_16.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_17.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_18.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_19.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_20.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_21.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_22.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/intro/pdf_en_23.jpg" class="rounded-0"></b-card-img-lazy>    
  </div>
    <Foot-section></Foot-section>
  </div>
</template>

<script>
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
import banner from "../assets/banner1.jpg";
  // import pdf_0 from "../assets/intro/pdf_0.jpg";
  // import pdf_1 from "../assets/intro/pdf_1.jpg";
  // import pdf_2 from "../assets/intro/pdf_2.jpg";
  // import pdf_3 from "../assets/intro/pdf_3.jpg";
  // import pdf_4 from "../assets/intro/pdf_4.jpg";
  // import pdf_5 from "../assets/intro/pdf_5.jpg";
  // import pdf_6 from "../assets/intro/pdf_6.jpg";
  // import pdf_7 from "../assets/intro/pdf_7.jpg";
  // import pdf_8 from "../assets/intro/pdf_8.jpg";
  // import pdf_9 from "../assets/intro/pdf_9.jpg";
  // import pdf_10 from "../assets/intro/pdf_10.jpg";
  // import pdf_11 from "../assets/intro/pdf_11.jpg";
  // import pdf_12 from "../assets/intro/pdf_12.jpg";
  // import pdf_13 from "../assets/intro/pdf_13.jpg";
  // import pdf_14 from "../assets/intro/pdf_14.jpg";
  // import pdf_15 from "../assets/intro/pdf_15.jpg";
  // import pdf_16 from "../assets/intro/pdf_16.jpg";
  // import pdf_17 from "../assets/intro/pdf_17.jpg";
  // import pdf_18 from "../assets/intro/pdf_18.jpg";
  // import pdf_19 from "../assets/intro/pdf_19.jpg";
  // import pdf_20 from "../assets/intro/pdf_20.jpg";
  // import pdf_21 from "../assets/intro/pdf_21.jpg";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Business",
  components: {
    HeadSection,
    FootSection
  },
  metaInfo() {
    return {
       title: this.$t("message.Bluedio_Mall"),
       titleTemplate: "%s",
      meta: [
         {
          name: "keywords",
          content: "Business",
        }
      ],
    };
  },
  data() {
    return {
      banner_url:banner,
      //  pdf_0_url:pdf_0,
      //   pdf_1_url:pdf_1,
      //   pdf_2_url:pdf_2,
      //   pdf_3_url:pdf_3,
      //   pdf_4_url:pdf_4,
      //   pdf_5_url:pdf_5,
      //   pdf_6_url:pdf_6,
      //   pdf_7_url:pdf_7,
      //   pdf_8_url:pdf_8,
      //   pdf_9_url:pdf_9,
      //   pdf_10_url:pdf_10,
      //   pdf_11_url:pdf_11,
      //   pdf_12_url:pdf_12,
      //   pdf_13_url:pdf_13,
      //   pdf_14_url:pdf_14,
      //   pdf_15_url:pdf_15,
      //   pdf_16_url:pdf_16,
      //   pdf_17_url:pdf_17,
      //   pdf_18_url:pdf_18,
      //   pdf_19_url:pdf_19,
      //   pdf_20_url:pdf_20,
      //   pdf_21_url:pdf_21,
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters([
      "lan","iszh"
    ])
  },

  watch: {
  },
  methods: {
  },
};
</script>
